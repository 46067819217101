import { isBrowser } from "../../../services/general"

export const hasSeenNotification = () =>
  isBrowser() && sessionStorage.getItem("hasSeenNotification")
    ? sessionStorage.getItem("hasSeenNotification") === "true"
    : false

export const setNotificationAsSeen = () =>
  isBrowser() ? sessionStorage.setItem("hasSeenNotification", "true") : null

export const createNotificationStructuredQuery = ({ collectionId }) => {
  return {
    structuredQuery: {
      select: {
        fields: [
          {
            fieldPath: "message",
          },
          {
            fieldPath: "startDate",
          },
          {
            fieldPath: "endDate",
          },
        ],
      },
      from: [{ collectionId }],
      where: {
        fieldFilter: {
          field: {
            fieldPath: "subdomains",
          },
          op: "EQUAL",
          value: {
            stringValue: process.env.GATSBY_WEBSITE_SUBDOMAIN,
          },
        },
      },
    },
  }
}
